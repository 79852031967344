import React from 'react'

import MediaQuery from 'react-responsive';

import logo from "../images/LoanSnap_Logo.png"
import equalHousingLogo from "../images/Equal_Housing_Opportunity.png"
import bbbLogo from "../images/bbb_logo.png"
import trustpilotLogo from "../images/Trustpilot_Logo.png"
import headerimage from "../images/header.png"

import Layout from '../components/layout'

const styles = {
  imageContainer: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  adImage: {
    maxHeight: '425px',
    width: '100vw',
    objectFit: 'cover',
    objectPosition: '20% 20%',
  },
  mobileAdImage: {
    maxHeight: '200px',
    width: '100vw',
    objectFit: 'cover',
    objectPosition: '20% 20%',
  },
  siteTitleWeb: {
    fontSize: '40px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.35',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#34393c',
    marginBottom: '6px',  
  },
  mobileTitleWeb: {
    fontSize: '32px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.35',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#34393c',
    textDecoration: 'none',   
    marginBottom: '6px',  
  },
  callToActionContainer: {
    background: '#f9f9f9',
    textAlign: 'center',
    paddingBottom: '25px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  mobileCallToActionContainer: {
    background: '#f9f9f9',
    textAlign: 'center',
    paddingBottom: '25px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  subtitleText: {
    paddingTop: '3px',
    fontSize: '22px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.36',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#34393c',
  },
  mobileSubtitleText: {
    paddingTop: '16px',
    fontSize: '17px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.41',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#34393c',
  },
  button: {
    width: '365px',
    height: '75px',
    borderRadius: '50px',
    borderStyle: 'none',
    backgroundColor: '#2fc992',
  },
  buttonText: {
    fontSize: '30px',
    fontWeight: '600',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
    textDecoration: 'none',
    display: 'block',
    padding: '20px',
  },
  mobileButton: {
    maxWidth: '250px',
    maxHeight: '50px',
    borderRadius: '50px',
    borderStyle: 'none',
    backgroundColor: '#2fc992',
  },
  mobileButtonText: {
    fontSize: '22px',
    fontWeight: '600',
    textAlign: 'center',
    color: '#ffffff',
    textDecoration: 'none',
    display: 'block',
    padding: '10px',
  },
  bodyHelper: {
    width: '690px',
    margin: 'auto',
    justifyContent:'center', 
    alignItems:'center',
  },
  webAdTextDiv: {
    paddingTop: '62px',
    width: '588px',
    display: 'flex',
    margin: 'auto',
    justifyContent:'center', 
    alignItems:'center',
    paddingBottom: '62px',
  },
  webAdText: {
    fontSize: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.41',
    letterSpacing: 'normal',
    color: '#585f63',
    textAlign: 'center justify',
  },
  mobileBodyHelper: {
    maxWidth: '282px',
    margin: 'auto',
    justifyContent:'center', 
    alignItems:'center',
  },
  mobileAdTextDiv: {
    maxWidth: '282px',
    padding: '40px 10px 40px 10px',
    display: 'flex',
    justifyContent:'center', 
    alignItems:'center',
    margin: 'auto',
  },
  mobileAdText: {
    maxWidth: '224px',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.36',
    letterSpacing: 'normal',
    color: '#585f63',
    textAlign: 'center justify',
  },
  webFooterDiv: {
    maxWidth: '100vw',
    paddingLeft: '119px',
    paddingRight: '119px',
    background: '#f9f9f9',
  },
  webLogoContainer: {
    maxWidth: '225px',
    float: 'left',
    paddingTop: '5px',
  },
  webLegalLinksText: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '4',
    letterSpacing: 'normal',
    color: '#585f63',
    float: 'left',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  webFinancialLogos: {
    float: 'right',
    paddingTop: '15px',
  },
  webEqualHousingLogo: {
    maxWidth: '25px',
    verticalAlign: 'middle',
  },
  webBBBLogo: {
    maxWidth: '75px',
    verticalAlign: 'middle',
    marginLeft: "20px",
  },
  webTrustPilotLogo: {
    maxWidth: '114px',
    verticalAlign: 'middle',
    marginLeft: "20px",
  },
  helper: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  mobileFooterDiv: {
    paddingLeft: '26px',
    paddingRight: '26px',
    background: '#f9f9f9',
    height: '200px',
  },
  mobileLogoContainer: {
    height: '42px',
  },
  mobileLoanSnapLogo: {
    maxWidth: '120px',
    paddingTop: '21px',
  },
  mobileLegalLinksDiv: {
    height: '100px',
    float: 'left',  
    background: '#f9f9f9',
  },
  mobileLegalLinksText: {
    fontSize: '12px',
    lineHeight: '2.5',
    color: '#585f63',
    paddingLeft: '44px',
    paddingTop: '13px',
    background: '#f9f9f9',
  },
  mobileLogoDiv: {
    height: '15px',
    paddingTop: '30px',
    background: '#f9f9f9',
    display: 'flex',
    justifyContent:'center', 
    alignItems:'center',
  },
  mobileEqualHousingLogo: {
    maxWidth: '20px',
    verticalAlign: 'middle',
  },
  mobileBBBLogo: {
    maxWidth: '58px',
    verticalAlign: 'middle',
    marginLeft: "12px",
  },
  mobileTrustPilotLogo: {
    maxWidth: '108px',
    verticalAlign: 'middle',
    marginLeft: "12px",
  }
}

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <MediaQuery query="(min-device-width: 768px)">
        <div className="imageContainer" style={styles.imageContainer}>
          <img src={headerimage} style={styles.adImage} />
        </div>
        <div>
        </div>
        <div className="callToActionContainer" style={styles.callToActionContainer}>
          <h1 className="siteTitleWeb" style={styles.siteTitleWeb}>Get Started With LoanSnap Today!</h1>
          <h3 className="subtitleText" style={styles.subtitleText}>Let's find out how much money you can save!</h3>
          <button className="button" style={styles.button}><a href ="https://secure.goloansnap.com/loanApplication/?" className="buttonText" style={styles.buttonText}>Apply Now</a></button>
        </div>
        <div className="bodyHelper" style={styles.bodyHelper}>
          <div className="webAdTextDiv" style={styles.webAdTextDiv}>
          </div>
        </div>
        <div className="webFooterDiv" style={styles.webFooterDiv}>
          <div className="webLogoContainer" style={styles.webLogoContainer}>
          <img src={logo} />
          </div>
          <div className="webLegalLinksText" style={styles.webLegalLinksText}>
          <a href="https://www.goloansnap.com/privacy-policy/">Privacy Policy</a> | <a href="https://www.goloansnap.com/terms-and-conditions/">Terms of Use</a>
          </div>
          <div className="webFinancialLogos" style={styles.webFinancialLogos}>
          <img src={equalHousingLogo} style={styles.webEqualHousingLogo}/> <img src={bbbLogo} style={styles.webBBBLogo} /> <img src={trustpilotLogo} style={styles.webTrustPilotLogo}/>
          </div>
        </div>
        </MediaQuery>
        <MediaQuery query="(max-device-width: 766px)">
        <div className="imageContainer" style={styles.imageContainer}>
          <img src={headerimage} style={styles.mobileAdImage} />
        </div>
        <div className="mobileCallToActionContainer" style={styles.mobileCallToActionContainer}>
        <h1 className="mobileTitleWeb" style={styles.mobileTitleWeb}>Get Started With LoanSnap Today!</h1>
          <h3 className="mobileSubtitleText" style={styles.mobileSubtitleText}>Let's find out how much money you can save!</h3>
          <button className="mobileButton" style={styles.mobileButton}><a href ="https://secure.goloansnap.com/loanApplication/?" className="mobileButtonText" style={styles.mobileButtonText}>Apply Now</a></button>
        </div>
        <div className="mobileAdTextDiv" style={styles.mobileAdTextDiv}>
        </div>
        <div className="mobileFooterDiv" style={styles.mobileFooterDiv}>
          <div className="mobileLogoContainer" style={styles.mobileLogoContainer}>
          <img src={logo} style={styles.mobileLoanSnapLogo}/>
          </div>
          <div className="mobileLegalLinksText" style={styles.mobileLegalLinksText}>
          <a href="https://www.goloansnap.com/privacy-policy/">Privacy Policy</a><br></br>
          <a href="https://www.goloansnap.com/terms-and-conditions/">Terms of Use</a><br></br>
          <a href="tel:888-680-5777">Call Us</a><br></br>
          </div>
          <div className="mobileLogoDiv" style={styles.mobileLogoDiv}>
          <img src={equalHousingLogo} style={styles.mobileEqualHousingLogo}/> <img src={bbbLogo} style={styles.mobileBBBLogo} /> <img src={trustpilotLogo} style={styles.mobileTrustPilotLogo}/>
          </div>
        </div>
        </MediaQuery>
      </Layout>
      
    )
  }
}

export default IndexPage

